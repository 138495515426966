import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Modal,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import "./errorDashboard.css";
import {
  Add,
  CalendarTodayOutlined,
  CancelOutlined,
  Check,
  Close,
  FileDownloadOutlined,
  FilterListOutlined,
  FolderOpenOutlined,
  Remove,
} from "@mui/icons-material";
import { Button, Card, Form } from "react-bootstrap";
import {
  getErrorCategoryListService,
  getFileCategoryListService,
} from "../../service/ErrorDashboard/ErrorDashboard";
import Select from "react-select";
import { useRef } from "react";
import DataNotFound from "../NotFound/DataNotFound";
import { useNavigate, useSearchParams } from "react-router-dom";
import { get, isEmpty } from "lodash";

function ErrorDashboard() {
  const errorCategory = [
    {
      label: "Account - Missing Mandatory Field",
      value: "Account - Missing Mandatory Field",
    },
    { label: "Account Not Found", value: "Account Not Found" },
    {
      label: "Account Update -  Missing Id and Domain",
      value: "Account Update -  Missing Id and Domain",
    },
    {
      label: "Acquired/Merged/Subsidiary - Missing Parent Domain",
      value: "Acquired/Merged/Subsidiary - Missing Parent Domain",
    },
    {
      label: "Contact - Missing Mandatory Field",
      value: "Contact - Missing Mandatory Field",
    },
    { label: "Country - Missing", value: "Country - Missing" },
    {
      label: "Data Mismatch - Contact Associated With Other Account",
      value: "Data Mismatch - Contact Associated With Other Account",
    },
    {
      label: "Data Mismatch - Location Associated With Other Account",
      value: "Data Mismatch - Location Associated With Other Account",
    },
    { label: "Domain - Generic", value: "Domain - Generic" },
    { label: "Duplicate Data - DataBase", value: "Duplicate Data - DataBase" },
    {
      label: "Duplicate Data - Imported CSV",
      value: "Duplicate Data - Imported CSV",
    },
    {
      label: "Duplicate Location - DataBase",
      value: "Duplicate Location - DataBase",
    },
    {
      label: "Duplicate Location - Imported File",
      value: "Duplicate Location - Imported File",
    },
    {
      label: "Employee Size - Missing / 0",
      value: "Employee Size - Missing / 0",
    },
    { label: "Industry - Incorrect ", value: "Industry - Incorrect " },
    { label: "Industry - Missing", value: "Industry - Missing" },
    {
      label: "Location - Missing Mandatory Field",
      value: "Location - Missing Mandatory Field",
    },
    { label: "Location Type - Incorrect", value: "Location Type - Incorrect" },
    {
      label: "Location Update - Missing Id",
      value: "Location Update - Missing Id",
    },
    {
      label: "Parent Account - Incorrect Dispostion",
      value: "Parent Account - Incorrect Dispostion",
    },
    { label: "Parent Account - Missing", value: "Parent Account - Missing" },
    {
      label: "Parent Account Domain - Missing",
      value: "Parent Account Domain - Missing",
    },
    {
      label: "Passing data Failed - Incorrect Operation Type",
      value: "Passing data Failed - Incorrect Operation Type",
    },
    { label: "Revenue - Missing / 0", value: "Revenue - Missing / 0" },
    { label: "Sub Industry - Incorrect", value: "Sub Industry - Incorrect" },
    { label: "Zoom Industry - Incorrect", value: "Zoom Industry - Incorrect" },
    { label: "Zoom Industry - Missing", value: "Zoom Industry - Missing" },
    {
      label: "Zoom Sub-Industry - Incorrect",
      value: "Zoom Sub-Industry - Incorrect",
    },
    { label: "UNEXPECTED_ERROR", value: "UNEXPECTED_ERROR" },
    {
      label: "Passing data Failed - Incorrect Data Type",
      value: "Passing data Failed - Incorrect Data Type",
    },
  ];

  const [params, setParams] = useSearchParams();
  console.log(params.get("type"));

  const typeRoutes = params.get("type");
  const startDateRoute = params.get("startDateTime");
  const endDateRoute = params.get("endDateTime");
  const fileNameRoute = params.get("fileName");

  const [value, setValue] = useState(typeRoutes || "account");
  const [fileValue, setFileValue] = useState(fileNameRoute || "");
  const [sourceValue, setSourceValue] = useState("");
  // const [isFilterReset, setFilterReset] = useState(false);[SM: future refrence]
  const [categoryVal, setCategoryVal] = useState([]);
  const [accordionCount, setAccordionCount] = useState(-1);
  const [startDate, setStartDate] = useState(startDateRoute || null);
  const [endDate, setEndDate] = useState(endDateRoute || null);
  const [showFilters, setShowFilters] = useState([]);

  const navigate = useNavigate();
  const resetDate = () => {
    setStartDate(null);
    setEndDate(null);
    if (params.has("startDateTime")) {
      params.delete("startDateTime");
      params.delete("endDateTime");
      setParams((params) => params);
    }
    handleClose();
  };

  const resetFile = () => {
    setFileValue("");
    if (params.has("fileName")) {
      params.delete("fileName");
      setParams((params) => params);
    }

    handleCloseFileSearch();
  };

  const resetSource = () => {
    setFileValue("");
    if (params.has("sourceName")) {
      params.delete("sourceName");
      setParams((params) => params);
    }
    setSourceValue("");
    handleCloseSourceSearch();
  };
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleFileSearch = () => {
    if (!inputFileElement?.current?.value) {
      return;
    }
    handleCloseFileSearch();
    setFileValue(inputFileElement?.current?.value);
  };

  const handleSourceSearch = () => {
    if (!inputSourceElement?.current?.value) {
      return;
    }
    handleCloseSourceSearch();
    setSourceValue(inputSourceElement?.current?.value);
  };

  const handleDateSearch = () => {
    console.log(
      "inputStartDateElement?.current?.value",
      inputStartDateElement?.current?.value
    );
    if (
      !inputStartDateElement?.current?.value &&
      !inputEndDateElement?.current?.value
    ) {
      return;
    }
    handleClose();
    setStartDate(inputStartDateElement?.current?.value);
    setEndDate(inputEndDateElement?.current?.value);
  };

  const [showFileSearch, setShowFileSearch] = useState(false);
  const [showSourceSearch, setShowSourceSearch] = useState(false);
  const inputSourceElement = useRef();
  const inputFileElement = useRef();
  const inputStartDateElement = useRef();
  const inputEndDateElement = useRef();

  const handleCloseFileSearch = () => {
    setShowFileSearch(false);
  };
  const handleShowFileSearch = () => setShowFileSearch(true);

  const handleCloseSourceSearch = () => {
    setShowSourceSearch(false);
  };
  const handleShowSourceSearch = () => setShowSourceSearch(true);

  const [showDownloadCard, setshowDownloadCard] = useState(false);
  const [downloadCardIndex, setDownloadCardIndex] = useState(-1);

  const handleCloseDownloadCard = () => setshowDownloadCard(false);
  const handleshowDownloadCard = () => setshowDownloadCard(true);

  const [showDownloadChildCard, setshowDownloadChildCard] = useState(false);
  const [downloadChildCardIndex, setDownloadChildCardIndex] = useState(-1);

  const handleCloseDownloadChildCard = () => setshowDownloadChildCard(false);
  const handleshowDownloadChildCard = () => setshowDownloadChildCard(true);

  const handleChildCard = (index) => {
    setDownloadChildCardIndex(index);
    handleshowDownloadChildCard();
  };

  const [errorCategoryList, setErrorCategoryList] = useState([]);
  const [filesCategoryList, setFilesCategoryList] = useState([]);
  const selectInputRef = useRef();

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setCategoryVal([]);
    setShowFilters([]);
    setStartDate(null);
    setEndDate(null);
    setFileValue("");
    setSourceValue("");

    setDownloadCardIndex(-1);
    setDownloadChildCardIndex(-1);
  };

  const isFiltersApplied = () => {
    if (fileValue !== "") {
      return true;
    }
    if (categoryVal.length > 0) {
      return true;
    }
    if (startDate || endDate) {
      return true;
    }
    if (sourceValue !== "") {
      return true;
    }
    return false;
  };

  useEffect(() => {
    getErrorCategoryList(value, false, false, null);
  }, [categoryVal, params, fileValue, sourceValue, startDate, endDate]);

  const getErrorCategoryList = async (
    data,
    download,
    isSolved,
    selectedCategory,
    filterReset = null
  ) => {
    try {
      let params = {};
      if (download) {
        params.download = download;
        params.fileName = downloadFileName;
        params.filter = {
          isSolved: isSolved ? !isSolved : null,
          category: selectedCategory.category,
        };
      }
      if (!download) {
        setErrorCategoryList([]);
        params.filter = {
          category: filterReset?.category ? null : categoryVal,
          startDateTime: filterReset?.startDateTime ? null : startDate,
          endDateTime: filterReset?.endDateTime ? null : endDate,
          fileName: filterReset?.fileName ? null : fileValue,
          source: sourceValue,
        };
      }

      for (let key in params.filter) {
        if (isEmpty(params.filter[key])) {
          delete params.filter[key];
        }
      }
      if (get(params, `filter.startDateTime`, null))
        params.filter.startDateTime = new Date(
          params.filter.startDateTime
        ).toISOString();
      if (get(params, `filter.endDateTime`, null))
        params.filter.endDateTime = new Date(
          params.filter.endDateTime
        ).toISOString();

      console.log("filters", params.filter);
      params.filter = JSON.stringify(params.filter);

      let resp = await getErrorCategoryListService(data, params);
      if (resp.data === "Job Submitted Successfully") {
        alert("Download request submitted");
        setDownloadCardIndex(-1);
        setDownloadChildCardIndex(-1);
        return;
      }
      setErrorCategoryList(resp.data);
    } catch (error) {
      console.log(error);
    }
  };
  console.log("---------->", errorCategoryList);

  const onDownloadClick = () => {
    let obj = downloadObject;
    if (obj.type === 0) {
      getErrorCategoryList(value, true, obj.isSolved, obj.data);
      resetDownloadFile();
      return;
    }
    filesCategory(obj.e, obj.data, true, obj.isSolved);
    resetDownloadFile();
  };

  const handleResetFilterClick = () => {
    setDownloadCardIndex(-1);
    setDownloadChildCardIndex(-1);
    handleAccoridanReset();
    setCategoryVal([]);
    setShowFilters([]);
    resetFile();
    resetDate();
    resetSource();
    navigate("/dashboard/error");
  };

  const filesCategory = async (event, data, download, isSolved) => {
    try {
      let params = {};
      if (download) {
        params.download = download;
        params.fileName = downloadFileName;
        params.filter = {
          fileId: data.id,
          category: data.category,
          isSolved: isSolved ? !isSolved : null,
          startDateTime: startDate,
          endDateTime: endDate,
        };
      } else {
        setFilesCategoryList([]);
        params.filter = {
          category: data.category,
          startDateTime: startDate,
          endDateTime: endDate,
          fileName: fileValue,
          source: sourceValue,
        };
      }

      for (let key in params.filter) {
        if (isEmpty(params.filter[key])) {
          delete params.filter[key];
        }
      }
      if (get(params, `filter.startDateTime`, null))
        params.filter.startDateTime = new Date(
          params.filter.startDateTime
        ).toISOString();
      if (get(params, `filter.endDateTime`, null))
        params.filter.endDateTime = new Date(
          params.filter.endDateTime
        ).toISOString();

      params.filter = JSON.stringify(params.filter);

      let resp = await getFileCategoryListService(value, params);
      if (resp.data === "Job Submitted Successfully") {
        alert("Download request submitted");
        return;
      }
      setFilesCategoryList(resp.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleAccoridanReset = () => {
    setAccordionCount(-1);
  };

  const handleCategoryChange = (selectedValue) => {
    const options = selectedValue?.map((data) => data.value);
    setCategoryVal(options);
    setShowFilters(selectedValue);
  };

  const customStyles = {
    control: (defaultStyles) => ({
      ...defaultStyles,
      minWidth: "300px",
    }),
    singleValue: (defaultStyles) => ({ ...defaultStyles, color: "#fff" }),
  };

  const CustomExpandIcon = ({ data, currentIndex }) => {
    return (
      <Box
        sx={{
          ".Mui-expanded & > .collapsIconWrapper": {
            display: "none",
          },
          ".expandIconWrapper": {
            display: "none",
          },
          ".Mui-expanded & > .expandIconWrapper": {
            display: "block",
          },
        }}
      >
        <div className="expandIconWrapper">
          <div className="col-12">
            <Tooltip
              onClick={() => {
                handleAccoridanReset();
              }}
              title="Close Window"
            >
              <Remove
                sx={{
                  color: "#0062F5",
                  fontSize: 40,
                }}
              ></Remove>
            </Tooltip>
          </div>
        </div>
        {/* TODO :: Due to slow response of data we remove the file expand icon, once api response will improve the className hide-file-expand-con will remove */}
        <div className="collapsIconWrapper hide-file-expand-icon">
          <Tooltip
            title="Open Window"
            onClick={(e) => {
              setAccordionCount(currentIndex);
              filesCategory(e, data, false, false);
            }}
          >
            <Add
              sx={{
                color: "#0062F5",
                fontSize: 40,
                marginLeft: "15px",
                // paddingTop: 2,
              }}
            ></Add>
          </Tooltip>
        </div>
      </Box>
    );
  };

  const [openModal, setModalOpen] = useState(false);
  const [downloadObject, setDownloadObject] = useState({});
  const [downloadFileName, setDownloadFileName] = useState(null);

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleModalOpen = (e, data, isSolved, type) => {
    setModalOpen(true);
    let obj = {
      e: e,
      data: data,
      isSolved: isSolved,
      type: type,
    };
    setDownloadObject(obj);
  };

  const resetDownloadFile = () => {
    handleModalClose();
    setDownloadFileName(null);
  };
  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "fit-content",
    bgcolor: "background.paper",
    border: "1px solid #0f0f0f",
    boxShadow: 24,
    p: 4,
    borderRadius: "5px",
  };
  return (
    <>
      <div>
        <Modal
          open={openModal}
          onClose={handleModalClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={modalStyle}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              <label>
                <span>
                  <FileDownloadOutlined></FileDownloadOutlined>
                </span>
                Enter File Name
              </label>
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              <TextField
                id="outlined-basic"
                label="Enter File Name"
                variant="outlined"
                size="small"
                value={downloadFileName}
                onChange={(e) => {
                  setDownloadFileName(e.target.value);
                }}
              />
            </Typography>
            <Box sx={{ marginTop: 2 }}>
              <div className="d-flex justify-content-between">
                <button
                  type="button"
                  class="btn btn-danger"
                  onClick={resetDownloadFile}
                >
                  <span>
                    <CancelOutlined></CancelOutlined>
                  </span>
                  Cancel
                </button>
                <button
                  type="button"
                  class="btn btn-success"
                  disabled={!downloadFileName}
                  onClick={onDownloadClick}
                >
                  <span>
                    <Check></Check>
                  </span>
                  Submit
                </button>
              </div>
            </Box>
          </Box>
        </Modal>
      </div>
      <div className="w-100">
        <div className="body-light-blue">
          <div className="container-fluid p-4">
            <div className="d-flex justify-content-start title-style flex-column">
              Error category
              <span className="subtitle-style">
                Track and understand data errors.
              </span>
            </div>

            <div className="d-flex pt-5">
              <Box sx={{ width: "100%" }}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                  >
                    <Tab value="account" label="Account" />
                    <Tab value="contact" label="Contact" />
                  </Tabs>
                </Box>
                <div className="d-flex justify-content-start align-items-baseline pt-4">
                  <h4>Filters:</h4>

                  <div className="position-relative z-3 ">
                    <button
                      variant="outlined"
                      type="button"
                      className=" btn btn-outline-dark file-picker-style"
                      onClick={handleShowFileSearch}
                    >
                      <span>
                        <FolderOpenOutlined></FolderOpenOutlined>
                        {fileValue || "File"}
                      </span>
                    </button>
                    {showFileSearch ? (
                      <Card className="mt-2 shadow position-absolute">
                        <Card.Header>
                          <div className="d-flex justify-content-between">
                            <label>File Search</label>
                            <button
                              className="close-button-style"
                              onClick={handleCloseFileSearch}
                            >
                              <span className="cusror-pointer">
                                <Close></Close>
                              </span>
                            </button>
                          </div>
                        </Card.Header>
                        <Card.Body>
                          <TextField
                            id="outlined-basic"
                            label="Enter File Name"
                            variant="outlined"
                            size="small"
                            inputRef={inputFileElement}
                            defaultValue={fileValue || null}
                          />
                          <div className="d-flex justify-content-end">
                            <Button
                              disabled={!fileValue}
                              style={!fileValue ? { border: "none" } : null}
                              variant="default text-primary"
                              onClick={resetFile}
                            >
                              Reset
                            </Button>
                            <Button
                              variant="default text-primary ms-2"
                              onClick={handleFileSearch}
                            >
                              Search
                            </Button>
                          </div>
                        </Card.Body>
                      </Card>
                    ) : null}
                  </div>

                  <div className="position-relative z-3">
                    <div className="filter-elements-spacing d-flex ">
                      <button
                        variant="outlined"
                        type="button"
                        className=" btn btn-outline-dark"
                      >
                        <span>
                          <FilterListOutlined></FilterListOutlined>
                        </span>
                      </button>

                      <Select
                        closeMenuOnSelect={false}
                        ref={selectInputRef}
                        styles={customStyles}
                        value={showFilters}
                        isMulti
                        options={errorCategory}
                        placeholder={<label>Category</label>}
                        onChange={(value) => handleCategoryChange(value)}
                      />
                    </div>
                  </div>

                  <div className="position-relative z-3">
                    <button
                      variant="outlined"
                      type="button"
                      className="filter-elements-spacing btn btn-outline-dark"
                      onClick={handleShow}
                    >
                      <span>
                        <CalendarTodayOutlined></CalendarTodayOutlined>
                        {startDate
                          ? endDate
                            ? startDate + " - " + endDate
                            : startDate
                          : endDate || "Created at"}
                      </span>
                    </button>
                    {show ? (
                      <Card className="mt-2 shadow position-absolute">
                        <Card.Header>
                          <div className="d-flex justify-content-between">
                            <label>Enter Dates</label>
                            <button
                              className="close-button-style"
                              onClick={handleClose}
                            >
                              <span className="cusror-pointer">
                                <Close></Close>
                              </span>
                            </button>
                          </div>
                        </Card.Header>
                        <Card.Body>
                          <Form>
                            <Form.Group
                              className="mb-3"
                              controlId="formStartDate"
                            >
                              <Form.Label>Start Date Time</Form.Label>
                              <Form.Control
                                type="datetime-local"
                                defaultValue={startDate || null}
                                ref={inputStartDateElement}
                              />
                            </Form.Group>
                            <Form.Group
                              className="mb-3"
                              controlId="formEndDate"
                            >
                              <Form.Label>End Date Time</Form.Label>
                              <Form.Control
                                type="datetime-local"
                                ref={inputEndDateElement}
                                defaultValue={endDate || null}
                              />
                            </Form.Group>
                          </Form>
                          <div className="d-flex justify-content-end">
                            <Button
                              variant="default text-primary"
                              onClick={resetDate}
                              disabled={!startDate && !endDate}
                              style={
                                !startDate && !endDate
                                  ? { border: "none" }
                                  : null
                              }
                            >
                              Reset
                            </Button>
                            <Button
                              variant="default text-primary ms-2"
                              onClick={handleDateSearch}
                            >
                              Search
                            </Button>
                          </div>
                        </Card.Body>
                      </Card>
                    ) : null}
                  </div>
                  <div className="position-relative">
                    <button
                      variant="outlined"
                      type="button"
                      className=" btn btn-outline-dark filter-elements-spacing"
                      onClick={handleShowSourceSearch}
                    >
                      <span>
                        <FolderOpenOutlined></FolderOpenOutlined>
                        {sourceValue || "Source Name"}
                      </span>
                    </button>
                    {showSourceSearch ? (
                      <Card
                        className="mt-2 shadow z-3 position-absolute"
                        style={{ width: "15rem" }}
                      >
                        <Card.Header>
                          <div className="d-flex justify-content-between">
                            <label>Source Search</label>
                            <button
                              className="close-button-style"
                              onClick={handleCloseSourceSearch}
                            >
                              <span className="cusror-pointer">
                                <Close></Close>
                              </span>
                            </button>
                          </div>
                        </Card.Header>
                        <Card.Body>
                          <TextField
                            id="outlined-basic"
                            label="Enter Source Name"
                            variant="outlined"
                            size="small"
                            inputRef={inputSourceElement}
                            value={sourceValue || null}
                          />
                          <div className="d-flex justify-content-end">
                            <Button
                              disabled={!sourceValue}
                              style={!sourceValue ? { border: "none" } : null}
                              variant="default text-primary"
                              onClick={resetSource}
                            >
                              Reset
                            </Button>
                            <Button
                              variant="default text-primary ms-2"
                              onClick={handleSourceSearch}
                            >
                              Search
                            </Button>
                          </div>
                        </Card.Body>
                      </Card>
                    ) : null}
                  </div>
                  {isFiltersApplied() && (
                    <div className="">
                      <button
                        variant="outlined"
                        type="button"
                        className="filter-elements-spacing btn btn-outline-danger"
                        onClick={handleResetFilterClick}
                      >
                        Reset Filters
                      </button>
                    </div>
                  )}
                </div>

                {errorCategoryList?.docs?.length > 0 ? (
                  errorCategoryList?.docs?.map((data, i) => (
                    <div
                      className="accordion-spacing-style"
                      key={data.category + i}
                    >
                      <Accordion expanded={accordionCount === i}>
                        <AccordionSummary
                          expandIcon={
                            <CustomExpandIcon data={data} currentIndex={i} />
                          }
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                          sx={{
                            height: 90,
                            padding: 5,
                          }}
                        >
                          <div className="col-12 row">
                            <div className="col-7 border-style-header mt-2 mb-2">
                              <Tooltip title={data.category}>
                                <h4 className="pt-4 header-text-card-style">
                                  {data.category}
                                </h4>
                              </Tooltip>
                            </div>
                            <div className="col-4 ">
                              <div
                                className="row errors-dialog-style"
                                style={{ marginLeft: "14px" }}
                              >
                                <div className="col-5">
                                  <div className="col-12">
                                    <div className="col-6">
                                      <h3 className="text-center">
                                        {Number(
                                          data.unresolvedErrorCount
                                        ).toLocaleString("en")}
                                      </h3>
                                    </div>
                                    <div className="col-6 text-center text-nowrap">
                                      Pending Errors
                                    </div>
                                  </div>
                                </div>
                                <div className="col-2 slash-style overflow-hidden">
                                  /
                                </div>
                                <div className="col-5">
                                  <div className="col-12">
                                    <div className="col-6">
                                      <h3 className="text-center">
                                        {Number(data.count).toLocaleString(
                                          "en"
                                        )}
                                      </h3>
                                    </div>
                                    <div className="col-6 text-center text-nowrap">
                                      Total Errors
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-1 pr-5 border-style-header-download text-center mt-2 mb-2">
                              <div className="col-12 position-relative ">
                                <div className="col-6 w-100">
                                  <FileDownloadOutlined
                                    onClick={(e) => {
                                      setDownloadCardIndex(i);
                                      handleshowDownloadCard();
                                    }}
                                    sx={{
                                      color: "#0062F5",
                                      fontSize: 55,
                                      paddingTop: 2,
                                    }}
                                  ></FileDownloadOutlined>
                                </div>
                                <div className="col-6">
                                  <span style={{ fontSize: "14px" }}>
                                    Download
                                  </span>
                                </div>
                                {showDownloadCard && downloadCardIndex === i ? (
                                  <Card className="mt-2 shadow position-absolute z-3 width-14 download-drawer-position">
                                    <Card.Header className="card-header-style">
                                      <div className="d-flex justify-content-between">
                                        <label>Download Menu</label>
                                        <button
                                          className="close-button-style"
                                          onClick={() => {
                                            handleCloseDownloadCard();
                                          }}
                                        >
                                          <span className="cusror-pointer">
                                            <Close></Close>
                                          </span>
                                        </button>
                                      </div>
                                    </Card.Header>
                                    <Card.Body>
                                      <label
                                        className="label-heading-style"
                                        onClick={(e) => {
                                          handleModalOpen(e, data, true, 0);
                                        }}
                                      >
                                        Download Pending Errors
                                        <span>
                                          <FileDownloadOutlined
                                            sx={{
                                              color: "#0062F5",
                                              fontSize: 22,
                                            }}
                                          ></FileDownloadOutlined>
                                        </span>
                                      </label>
                                      <label
                                        className="label-heading-style"
                                        onClick={(e) => {
                                          handleModalOpen(e, data, false, 0);
                                        }}
                                      >
                                        Download Total Errors
                                        <span>
                                          <FileDownloadOutlined
                                            sx={{
                                              color: "#0062F5",
                                              fontSize: 22,
                                            }}
                                          ></FileDownloadOutlined>
                                        </span>
                                      </label>
                                    </Card.Body>
                                  </Card>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </AccordionSummary>
                        <AccordionDetails>
                          {filesCategoryList?.rows?.length > 0 ? (
                            <div className="w-100">
                              <table className="table table-hover">
                                <thead className="thead-style">
                                  <tr>
                                    <th scope="col" className="text-left">
                                      File Name
                                    </th>
                                    <th scope="col" className="text-center">
                                      Counts
                                    </th>
                                    <th scope="col" className="text-center">
                                      Download
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {filesCategoryList?.rows?.map((data, i) => (
                                    <tr
                                      key={data.id + i + 1}
                                      className="table-body-style"
                                    >
                                      <td className="text-left IfFep">
                                        {data.name}
                                      </td>
                                      <td className="text-center IfFep">
                                        {data.unresolvedErrorCount} /{" "}
                                        {data.totalCount}
                                      </td>
                                      <td className="text-center IfFep">
                                        <FileDownloadOutlined
                                          className="cursor-pointer"
                                          onClick={(e) => {
                                            handleChildCard(i);
                                          }}
                                          sx={{
                                            color: "#0062F5",
                                            fontSize: 30,
                                          }}
                                        ></FileDownloadOutlined>
                                        {showDownloadChildCard &&
                                        downloadChildCardIndex === i ? (
                                          <Card className="mt-2 shadow position-absolute z-3 width-14">
                                            <Card.Header className="card-header-style">
                                              <div className="d-flex justify-content-between">
                                                <label>Download Menu</label>
                                                <button
                                                  className="close-button-style"
                                                  onClick={() => {
                                                    handleCloseDownloadChildCard();
                                                  }}
                                                >
                                                  <span className="cusror-pointer">
                                                    <Close></Close>
                                                  </span>
                                                </button>
                                              </div>
                                            </Card.Header>
                                            <Card.Body>
                                              <label
                                                className="label-heading-style"
                                                onClick={(e) => {
                                                  handleModalOpen(
                                                    e,
                                                    data,
                                                    true,
                                                    1
                                                  );
                                                }}
                                              >
                                                Download Pending Errors
                                                <span>
                                                  <FileDownloadOutlined
                                                    sx={{
                                                      color: "#0062F5",
                                                      fontSize: 22,
                                                    }}
                                                  ></FileDownloadOutlined>
                                                </span>
                                              </label>
                                              <label
                                                className="label-heading-style"
                                                onClick={(e) => {
                                                  handleModalOpen(
                                                    e,
                                                    data,
                                                    false,
                                                    1
                                                  );
                                                }}
                                              >
                                                Download Total Errors
                                                <span>
                                                  <FileDownloadOutlined
                                                    sx={{
                                                      color: "#0062F5",
                                                      fontSize: 22,
                                                    }}
                                                  ></FileDownloadOutlined>
                                                </span>
                                              </label>
                                            </Card.Body>
                                          </Card>
                                        ) : null}
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          ) : (
                            <DataNotFound></DataNotFound>
                          )}
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  ))
                ) : (
                  <DataNotFound></DataNotFound>
                )}
              </Box>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ErrorDashboard;
