import { Box, Tab, Tabs } from "@mui/material";
import "./index.css";
import React, { useEffect, useState } from "react";
import LiveAccount from "./LiveAccount";
import { Cached } from "@mui/icons-material";
import LiveContact from "./LiveContact";
import { getJobListService } from "../../service/JobList/JobList";

function LiveDashboard() {
  const [value, setValue] = useState("account");
  const [jobList, setJobList] = useState([]);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(1);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setLimit(10);
    setOffset(1);
  };
  useEffect(() => {
    const abortController = new AbortController();
    getJobList(value, abortController.signal);
    return () => {
      abortController.abort();
    };
  }, [limit, offset, value]);

  const getJobList = async (data, signal) => {
    try {
      setJobList([]);
      let params = {
        getDataCount: true,
        "jobType[]": data === "account" ? "uploadAccount" : "uploadContact",
        pageSize: limit,
        pageNo: offset - 1,
      };
      let resp = await getJobListService(params, signal);
      setJobList(resp.data);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <div>
        <div className="d-flex justify-content-between title-style">
          <div className="d-flex flex-column">
            Jobs Stats
            <span className="subtitle-style">
              Monitor and supervise real-time jobs status.
            </span>
          </div>
          <button
            className="refresh-button"
            onClick={() => {
              getJobList(value);
            }}
          >
            <Cached sx={{ color: "#fff" }}></Cached> Refresh
          </button>
        </div>
        <div className="d-flex pt-5">
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab value="account" label="Account" />
                <Tab value="contact" label="Contact" />
              </Tabs>
            </Box>
            {value === "account" && (
              <Box sx={{ width: "100%" }}>
                <LiveAccount
                  importFileList={jobList}
                  limit={limit}
                  offset={offset}
                  setLimit={setLimit}
                  setOffset={setOffset}
                ></LiveAccount>
              </Box>
            )}
            {value === "contact" && (
              <Box sx={{ width: "100%" }}>
                <LiveContact
                  limit={limit}
                  offset={offset}
                  setLimit={setLimit}
                  setOffset={setOffset}
                  importFileList={jobList}
                ></LiveContact>
              </Box>
            )}
          </Box>
        </div>
      </div>
    </>
  );
}

export default LiveDashboard;
