import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
// import { useDispatch, useSelector } from 'react-redux';
// import { userActionCreator } from '../../state';
import nexsales from "../../assets/nexsales.png";
import { Avatar } from "@mui/material";
import "./index.css";

const Navbar = () => {
  const userState = useState({});
  //   const userState? = useSelector(state => state.user)
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const [anchorElNav, setAnchorElNav] = useState(null);
  const navigate = useNavigate();

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  // const [anchorElUser, setAnchorElUser] = useState(null);
  // const handleOpenUserMenu = (event) => {
  //   setAnchorElUser(event.currentTarget)
  // }
  // const handleCloseUserMenu = () => {
  //   setAnchorElUser(null);
  // }

  // useEffect(() => {
  // }, [])

  //   const dispatch = useDispatch()
  //
  const onLogoutClick = () => {
    localStorage.clear();
    // navigate("/auth/login.html");
    window.location.href = "/auth/login.html";
  };

  const renderBoxAdminNavbar = (
    <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
      <Button
        onClick={handleCloseNavMenu}
        sx={{ my: 2, color: "white", display: "block" }}
      >
        <NavLink className="anchor-tag" to="/account">
          Accounts
        </NavLink>
      </Button>
      <Button
        onClick={handleCloseNavMenu}
        sx={{ my: 2, color: "white", display: "block" }}
      >
        <NavLink className="anchor-tag" to={`/profile/${userState?.userID}`}>
          Profile
        </NavLink>
      </Button>
      <Button
        onClick={handleCloseNavMenu}
        sx={{ my: 2, color: "white", display: "block" }}
      >
        <NavLink className="anchor-tag" to="/account-list">
          Account List
        </NavLink>
      </Button>
    </Box>
  );

  const renderBoxUserNavbar = (
    <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" }, mt: 1 }}>
      <Button
        onClick={handleCloseNavMenu}
        sx={{ my: 2, color: "white", display: "block" }}
      >
        <NavLink className="anchor-tag" to={`/profile/${userState?.userID}`}>
          Profile
        </NavLink>
      </Button>
      <Button
        onClick={handleCloseNavMenu}
        sx={{ my: 2, color: "white", display: "block" }}
      >
        <NavLink className="anchor-tag" to="/credit">
          Credit
        </NavLink>
      </Button>
      <Button
        onClick={handleCloseNavMenu}
        sx={{ my: 2, color: "white", display: "block" }}
      >
        <NavLink className="anchor-tag" to="/account-list">
          Account List
        </NavLink>
      </Button>
    </Box>
  );

  const renderMenuAdminNavbar = (
    <Menu
      id="menu-appbar"
      anchorEl={anchorElNav}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      open={Boolean(anchorElNav)}
      onClose={handleCloseNavMenu}
      sx={{
        display: { xs: "block", md: "none" },
      }}
    >
      <MenuItem>
        <NavLink className="anchor-tag" to={`/profile/${userState?.userID}`}>
          Profile
        </NavLink>
      </MenuItem>
      <MenuItem>
        <NavLink className="anchor-tag" to="/account">
          Accounts
        </NavLink>
      </MenuItem>
    </Menu>
  );

  const renderMenuUserNavbar = (
    <Menu
      id="menu-appbar"
      anchorEl={anchorElNav}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      open={Boolean(anchorElNav)}
      onClose={handleCloseNavMenu}
      sx={{
        display: { xs: "block", md: "none" },
      }}
    >
      <MenuItem>
        <NavLink className="anchor-tag" to={`/profile/${userState?.userID}`}>
          Profile
        </NavLink>
      </MenuItem>
      <MenuItem>
        <NavLink className="anchor-tag" to="/credit">
          Credit
        </NavLink>
      </MenuItem>
      <MenuItem>
        <NavLink className="anchor-tag" to="/account-list">
          Account List
        </NavLink>
      </MenuItem>
    </Menu>
  );

  return (
    <>
      <AppBar position="static" className="navbar-style">
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            {/* <AdbIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} /> */}
            {/* <img id="nexsales-logo" style={{ display: { xs: 'flex', md: 'none' }, mr: 1 }}
              alt="nexsales-logo" className="nexsales-logo" src={nexsales} /> */}
            <Typography
              variant="h6"
              noWrap
              // component="a"
              className="anchor-tag"
              sx={{
                mr: 2,
                display: { xs: "none", md: "flex" },
                fontWeight: 700,
                color: "inherit",
                textDecoration: "none",
              }}
            >
              <NavLink
                className="anchor-tag"
                to={
                  userState?.roleName?.toLowerCase() === "admin" ? "admin" : ""
                }
              >
                {/* NEXSALES PROTOTYPE */}
                <Avatar
                  alt="Nexsales Logo"
                  src={nexsales}
                  sx={{ height: "70px", width: "70px" }}
                />
              </NavLink>
            </Typography>
            {userState?.isAuthenticated
              ? userState?.roleName?.toLowerCase() === "admin"
                ? renderBoxAdminNavbar
                : renderBoxUserNavbar
              : ""}

            {/* <AdbIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} /> */}
            {/* <img id="nexsales-logo" style={{ display: { xs: 'flex', md: 'none' }, mr: 1 }}
              alt="nexsales-logo" className="nexsales-logo" src={nexsales} /> */}
            <Typography
              variant="h5"
              noWrap
              // component="a"
              className="anchor-tag"
              sx={{
                mr: 2,
                display: { xs: "flex", md: "none" },
                flexGrow: 1,
                fontWeight: 700,
                color: "inherit",
                textDecoration: "none",
              }}
            >
              <NavLink
                className="anchor-tag"
                to={
                  userState?.roleName?.toLowerCase() === "admin" ? "admin" : ""
                }
              >
                {/* NEXSALES PROTOTYPE */}
                <Avatar
                  alt="Nexsales Logo"
                  src={nexsales}
                  sx={{ height: "70px", width: "70px" }}
                />
              </NavLink>
            </Typography>

            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              {userState?.isAuthenticated
                ? userState?.roleName?.toLowerCase() === "admin"
                  ? renderMenuAdminNavbar
                  : renderMenuUserNavbar
                : ""}
            </Box>
            <div className="d-flex justify-content-end w-100">
              <Button sx={{ my: 2, color: "white", display: "block" }}>
                <NavLink
                  className={`float-right nav-link`}
                  to=""
                  onClick={onLogoutClick}
                >
                  Logout
                </NavLink>
              </Button>
            </div>
          </Toolbar>
        </Container>
      </AppBar>
    </>
  );
};

export default Navbar;
