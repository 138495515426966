import React from "react";
import './DataNotFound.scss'
import SearchOffOutlinedIcon from "@mui/icons-material/SearchOffOutlined";

function DataNotFound() {
  return (
    <div className="data-not-found-container">
      <SearchOffOutlinedIcon sx={{ fontSize: '5rem', color: "#525252cc" }} />
      <p>No Data Found</p>
    </div>
  );
}

export default DataNotFound;
