import httpClient from "../api-interceptor/httpClient";
import _ from "lodash";

export const getUploadReport = async (type, params) => {
  try {
    const response = await httpClient.get(`/uploadReport/${type}`, {
      headers: { "Content-type": "application/json" },
      params: params,
    });
    return response;
  } catch (error) {
    throw new Error(error);
  }
};
