import httpClient from "../api-interceptor/httpClient";
import _ from "lodash";

export const getJobListService = async (params, signal) => {
  try {
    const response = await httpClient.get(`/jobs`, {
      headers: { "Content-type": "application/json" },
      params: params,
      signal: signal,
    });
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const getSignedUrlService = async (jobId) => {
  try {
    const response = await httpClient.get(`/jobs/${jobId}/getSignedURL`, {
      headers: { "Content-type": "application/json" },
    });
    return response;
  } catch (error) {
    throw new Error(error);
  }
};
