import React from "react";
import "./index.css";
import dateFormat from "dateformat";
import DataNotFound from "../NotFound/DataNotFound";
import Paginate from "../../layouts/Paginate/Paginate";

function AccountHome({ uploadReportList, limit, offset, setLimit, setOffset }) {
  return (
    <>
      <div className="container-fluid mt-4">
        <div className="d-flex justify-content-around">
          <div className="card total-processing-files-contact account-home flex-align-center">
            <h5>Accounts</h5>
            <label className="details-card-contact no-mrgin-left">
              {uploadReportList.totalAccountCounts
                ? Number(uploadReportList.totalAccountCounts).toLocaleString(
                    "en"
                  )
                : 0}
            </label>
          </div>
          <div className="card total-processing-contact account-branches-home flex-align-center">
            <h5>Account Branches</h5>
            <label className="details-card-contact no-mrgin-left">
              {uploadReportList.totalAccountLocationCounts
                ? Number(
                    uploadReportList.totalAccountLocationCounts
                  ).toLocaleString("en")
                : 0}
            </label>
          </div>
        </div>
        {uploadReportList?.rows?.length > 0 ? (
          <div className="card shadow-sm rounded mt-4 m-3 table-overflow-style">
            <table className="table table-hover table-bordered">
              <thead className="thead-style">
                <tr className="upper-header">
                  <th colSpan={1} className="text-center"></th>
                  <th colSpan={1} className="text-center">
                    Date
                  </th>
                  <th colSpan={2} className="text-center">
                    Records Before Upload
                  </th>
                  <th colSpan={1} className="text-center">
                    Imported Files
                  </th>
                  <th colSpan={1} className="text-center">
                    Total Records
                  </th>
                  <th colSpan={2} className="text-center">
                    Passed
                  </th>
                  <th colSpan={2} className="text-center">
                    Failed
                  </th>
                  <th colSpan={1} className="text-center">
                    Data Source
                  </th>
                  <th colSpan={1} className="text-center">
                    Uploaded by
                  </th>
                </tr>
                <tr className="lower-header">
                  <th scope="col" className="text-left"></th>
                  <th scope="col" className="text-left"></th>
                  <th scope="col" className="text-center">
                    Accounts
                  </th>
                  <th scope="col" className="text-center">
                    Locations
                  </th>
                  <th scope="col" className="text-center"></th>
                  <th scope="col" className="text-center"></th>
                  <th scope="col" className="text-center">
                    Total
                  </th>
                  <th scope="col" className="text-center">
                    Percentage
                  </th>
                  <th scope="col" className="text-center">
                    Total
                  </th>
                  <th scope="col" className="text-center">
                    Percentage
                  </th>
                  <th scope="col" className="text-center"></th>
                  <th scope="col" className="text-center"></th>
                </tr>
              </thead>
              <tbody>
                {uploadReportList?.rows?.map((e, i) => (
                  <tr
                    key={e.jobCreatedAt + e.jobCreatedBy}
                    className="table-body-style"
                  >
                    <td className="text-center IfFep">
                      {limit * (offset - 1) + i + 1}.
                    </td>
                    <td className="text-left IfFep">
                      {dateFormat(e?.jobCreatedAt, "mmmm dS, yyyy")}
                    </td>
                    <td className="text-center IfFep">
                      {Number(e.beforeUploadAccountCounts) || "-"}
                    </td>
                    <td className="text-center IfFep">
                      {Number(e.beforeUploadLocationCounts) || "-"}
                    </td>
                    <td className="text-center IfFep">
                      {Number(e.fileCount).toLocaleString("en") || "-"}
                    </td>
                    <td className="text-center IfFep">
                      <label className="badge-style-count">
                        <span className="dot-style"></span>
                        <strong>
                          {Number(e.totalProcessed).toLocaleString("en") || "-"}
                        </strong>
                      </label>
                    </td>
                    <td className="text-center IfFep">
                      <label className="badge-style-complete-count">
                        <span className="dot-style-complete"></span>
                        <strong>
                          {Number(e.totalImported).toLocaleString("en") || "-"}
                        </strong>
                      </label>
                    </td>
                    <td className="text-center IfFep">
                      {Number(e.totalProcessed)
                        ? +(
                            +(
                              (((e.totalImported ?? 0) * 100) /
                                e.totalProcessed) *
                              100
                            ) / 100
                          ).toFixed(2)
                        : 0}
                      %
                    </td>
                    <td className="text-center IfFep">
                      <label className="badge-style-failed-count">
                        <span className="dot-style-failed"></span>
                        <strong>
                          {Number(e.totalErrored).toLocaleString("en") || "-s"}
                        </strong>
                      </label>
                    </td>
                    <td className="text-center IfFep">
                      {Number(e.totalProcessed)
                        ? +(
                            (((e.totalErrored * 100) / e.totalProcessed) *
                              100) /
                            100
                          ).toFixed(2)
                        : 0}
                      %
                    </td>
                    <td className="text-center IfFep">
                      {e.fileSource ? e.fileSource : "-"}
                    </td>
                    <td className="text-center IfFep ">
                      <label className="updated-by-badge">
                        {e.jobCreatedBy || "-"}
                      </label>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="d-flex align-items-center justify-content-center">
              <Paginate
                totalCount={uploadReportList.count}
                limit={limit}
                offset={offset}
                setLimit={setLimit}
                setOffset={setOffset}
              />
            </div>
          </div>
        ) : (
          <DataNotFound></DataNotFound>
        )}
      </div>
    </>
  );
}

export default AccountHome;
