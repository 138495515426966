import { Cached } from "@mui/icons-material";
import { Box, Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";
import AccountHome from "./AccountHome";
import ContactHome from "./ContactHome";
import "./index.css";
import { getUploadReport } from "../../service/Home/Home";

function Home() {
  const [value, setValue] = useState("account");
  const [uploadReport, setUploadReport] = useState([]);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(1);

  const handleChange = (event, newValue) => {
    console.log("value tab chnage called============>", newValue);
    setValue(newValue);
    setLimit(10);
    setOffset(1);
  };
  useEffect(() => {
    getJobList(value);
  }, [limit, offset, value]);

  const getJobList = async (data) => {
    console.log("value job list called============>");

    try {
      setUploadReport([]);
      let params = {
        pageSize: limit,
        pageNumber: offset - 1,
      };
      let resp = await getUploadReport(data, params);
      console.log("response data============>", resp);

      setUploadReport(resp.data);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div>
      <div className="d-flex justify-content-between title-style">
        <div className="d-flex flex-column">
          Master Database Upload
          <span className="subtitle-style">
            Efficiently Track your Database Progression.
          </span>
        </div>
        <button
          className="refresh-button"
          onClick={() => {
            getJobList(value);
          }}
        >
          <Cached sx={{ color: "#fff" }}></Cached> Refresh
        </button>
      </div>
      <div className="d-flex pt-5">
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab value="account" label="Account" />
              <Tab value="contact" label="Contact" />
            </Tabs>
          </Box>
          {value === "account" && (
            <Box sx={{ width: "100%" }}>
              <AccountHome
                uploadReportList={uploadReport}
                limit={limit}
                offset={offset}
                setLimit={setLimit}
                setOffset={setOffset}
              />
            </Box>
          )}
          {value === "contact" && (
            <Box sx={{ width: "100%" }}>
              <ContactHome
                uploadReportList={uploadReport}
                limit={limit}
                offset={offset}
                setLimit={setLimit}
                setOffset={setOffset}
              />
            </Box>
          )}
        </Box>
      </div>
    </div>
  );
}

export default Home;
