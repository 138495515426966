const DEBUG = process.env.REACT_APP_NODE_ENV == "DEV";

const errorInterceptor = (axiosInstance) => {
  axiosInstance.interceptors.response.use(
    (response) => {
      document.body.classList.remove("loading-indicator");
      //Response Successful
      return response;
    },
    (error) => {
      console.log(error.response.status);
      document.body.classList.remove("loading-indicator");
      if (error?.response?.status === 401) {
        //Unauthorized
        //redirect to Login
        document.location.href = "/auth/login.html";
      } else {
        //dispatch your error in a more user friendly manner
        if (DEBUG) {
          //easier debugging
          console.group("Error");
          console.log(error);
          console.groupEnd();
        }
      }
    }
  );
};

const updateHeaderInterceptor = (axiosInstance) => {
  axiosInstance.interceptors.request.use(
    (config) => {
      document.body.classList.add("loading-indicator");
      const jwtToken = localStorage.getItem("id_token");
      config.headers["authorization"] = "Bearer " + jwtToken;
      return config;
    },
    (error) => {}
  );
};

export { errorInterceptor, updateHeaderInterceptor };
