import httpClient from "../api-interceptor/httpClient";
import _ from "lodash";

export const getErrorCategoryListService = async (type, params) => {
  try {
      const response = await httpClient.get(`/importError/${type}`, {
        headers: { "Content-type": "application/json" }, params: params
      })
      return response
    } catch (error) {
      throw new Error(error)
    }
}

export const getFileCategoryListService = async (type, params) => {
  try {
      const response = await httpClient.get(`/importError/${type}/files`, {
        headers: { "Content-type": "application/json" }, params: params
      })
      return response
    } catch (error) {
      throw new Error(error)
    }
}
