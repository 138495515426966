import { Box, Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";
import "./index.css";
import { Cached, FileDownloadOutlined } from "@mui/icons-material";
import dateFormat from "dateformat";
import DataNotFound from "../NotFound/DataNotFound";
import {
  getJobListService,
  getSignedUrlService,
} from "../../service/JobList/JobList";
import Paginate from "../../layouts/Paginate/Paginate";
import _ from "lodash";

function DownloadDashboard() {
  const [value, setValue] = useState("account");
  const [jobList, setJobList] = useState([]);
  const [totalJobListCount, setTotalJobListCount] = useState(0);
  const [paginateParams, setPaginateParams] = useState({
    limit: 10,
    offset: 1,
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
    getJobList(newValue);
  };

  useEffect(() => {
    resetPaginationParams();
  }, [value]);

  useEffect(() => {
    getJobList(value);
  }, [paginateParams]);

  function resetPaginationParams() {
    setPaginateParams((prev) => {
      const temp = _.cloneDeep(prev);
      temp.limit = 10;
      temp.offset = 1;
      return temp;
    });
  }

  function setLimit(value) {
    setPaginateParams((prev) => {
      const temp = _.cloneDeep(prev);
      temp.limit = value;
      return temp;
    });
  }

  function setOffset(value) {
    setPaginateParams((prev) => {
      const temp = _.cloneDeep(prev);
      temp.offset = value;
      return temp;
    });
  }

  const getJobList = async (data) => {
    try {
      setJobList([]);
      let params = {
        pageNo: paginateParams.offset - 1,
        pageSize: paginateParams.limit,
        "jobType[]":
          data === "account"
            ? "importAccountErrorDownload"
            : "importContactErrorDownload",
      };
      let resp = await getJobListService(params);
      setJobList(resp.data.docs);
      setTotalJobListCount(resp.data.totalCount);
    } catch (error) {
      console.log(error);
    }
  };

  const downloadData = async (e, data) => {
    e.preventDefault();
    try {
      let resp = await getSignedUrlService(data.jobId);
      window.open(resp?.data?.url, "_blank");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div>
        <div className="d-flex justify-content-between title-style">
          <div className="d-flex flex-column">
            Download History
            <span className="subtitle-style">
              Track and manage download history for error category.
            </span>
          </div>
          <button
            className="refresh-button"
            onClick={() => {
              getJobList(value);
            }}
          >
            <Cached sx={{ color: "#fff" }}></Cached> Refresh
          </button>
        </div>
        <div className="d-flex pt-5">
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab value="account" label="Account" />
                <Tab value="contact" label="Contact" />
              </Tabs>
            </Box>

            {totalJobListCount > 0 ? (
              <div className="card shadow-sm rounded mt-4">
                <table className="table table-hover">
                  <thead className="thead-style">
                    <tr>
                      <th scope="col" className="text-center"></th>
                      <th scope="col" className="text-left">
                        FileName
                      </th>
                      <th scope="col" className="text-center">
                        Count
                      </th>
                      <th scope="col" className="text-center">
                        Status
                      </th>
                      <th scope="col" className="text-center">
                        Requested Date
                      </th>
                      <th scope="col" className="text-center">
                        Requested Day & Time
                      </th>
                      <th scope="col" className="text-center">
                        Download
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {jobList.map((data, i) => (
                      <tr
                        key={data.id + data.jobId}
                        className="table-body-style"
                      >
                        <td className="text-center IfFep">
                          {paginateParams.limit * (paginateParams.offset - 1) +
                            i +
                            1}
                          .
                        </td>
                        <td className="text-left IfFep">{data.fileName}</td>
                        <td className="text-center IfFep">
                          {data.status === "Processing"
                            ? data.processed.toLocaleString() +
                              "/" +
                              data.rowCount.toLocaleString()
                            : data.status === "Completed"
                            ? data.rowCount.toLocaleString()
                            : 0}
                        </td>
                        <td className="text-center IfFep">
                          {data.status === "Failed" ? (
                            <label className="badge-style-failed">
                              <span className="dot-style-failed"></span>{data.status}
                            </label>
                          ) : data.status === "Completed" ? (
                            <label className="badge-style-complete">
                              <span className="dot-style-complete"></span>
                              {data.status}
                            </label>
                          ) : (
                            <label className="badge-style">
                              <span className="dot-style"></span>{data.status}
                            </label>
                          )}
                        </td>
                        <td className="text-center IfFep">
                          {dateFormat(data.createdAt, "mmmm dS, yyyy")}
                        </td>
                        <td className="text-center IfFep">
                          {dateFormat(data.createdAt, "dddd")}{" "}
                          {dateFormat(data.createdAt, "shortTime")}
                        </td>
                        <td className="text-center IfFep">
                          <button
                            onClick={(e) => {
                              downloadData(e, data);
                            }}
                            className={
                              data.status !== "Completed"
                                ? "cursor-not-allowed"
                                : ``
                            }
                            style={{
                              backgroundColor: "transparent",
                              border: "none",
                            }}
                            disabled={data.status !== "Completed"}
                          >
                            <FileDownloadOutlined
                              sx={
                                data.status !== "Completed"
                                  ? {
                                      color: "#8da3b9",
                                      fontSize: 30,
                                    }
                                  : { color: "#0062F5", fontSize: 30 }
                              }
                            ></FileDownloadOutlined>
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="d-flex justify-content-center">
                  <div>
                    <Paginate
                      totalCount={totalJobListCount}
                      limit={paginateParams.limit}
                      setLimit={setLimit}
                      offset={paginateParams.offset}
                      setOffset={setOffset}
                    ></Paginate>
                  </div>
                </div>
              </div>
            ) : (
              <DataNotFound></DataNotFound>
            )}
          </Box>
        </div>
      </div>
    </>
  );
}

export default DownloadDashboard;
