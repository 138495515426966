import React from "react";
import "./index.css";
import {
  ErrorOutline,
  AccessTimeOutlined,
  CloudUploadOutlined,
  LayersOutlined,
} from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";

function SideNav() {
  const navigate = useNavigate();
  const menus = [
    {
      icon: <LayersOutlined></LayersOutlined>,
      name: "Master Database Progress",
      path: "/",
    },
    {
      icon: <ErrorOutline></ErrorOutline>,
      name: "Error Category",
      path: "/dashboard/error",
    },
    {
      icon: <AccessTimeOutlined></AccessTimeOutlined>,
      name: "Download History",
      path: "/dashboard/download",
    },
    {
      icon: <CloudUploadOutlined></CloudUploadOutlined>,
      name: "Jobs Stats",
      path: "/dashboard/live",
    },
  ];
  const location = useLocation();
  console.log(location.pathname);
  return (
    <>
      <div className="d-flex justify-content-center flex-column">
        {menus.map((ele) => (
          <div
            key={ele.name}
            className={`menu-element-style m-1 ${
              location.pathname === ele.path
                ? "menu-element-style-marked"
                : "menu-element-style"
            }`}
            onClick={() => {
              navigate(ele.path);
            }}
          >
            <span className="element-text-style">{ele.icon}</span>
            <label className="element-text-style">{ele.name}</label>
          </div>
        ))}
      </div>
    </>
  );
}

export default SideNav;
