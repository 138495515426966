import { FileDownloadOutlined, LaunchOutlined } from "@mui/icons-material";
import React, { useState } from "react";
import DataNotFound from "../NotFound/DataNotFound";
import dateFormat from "dateformat";
import { getSignedUrlService } from "../../service/JobList/JobList";
import { Button } from "react-bootstrap";
import Paginate from "../../layouts/Paginate/Paginate";
import { Link } from "react-router-dom";

function LiveAccount({ importFileList, limit, offset, setLimit, setOffset }) {
  const downloadData = async (e, data) => {
    e.preventDefault();
    try {
      let resp = await getSignedUrlService(data.jobId);
      window.open(resp.data.url, "_blank");
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <div className="container-fluid ">
        <div className="d-flex justify-content-around  mt-4 row">
          <div className="p-4 bg-account flex-align-center">
            <h5>Total Account</h5>
            <label className="details-card no-mrgin-left">
              {importFileList.totalAccount?.toLocaleString() || "-"}
            </label>
          </div>
          <div className="p-4 bg-branches flex-align-center">
            <h5>Total Branches</h5>
            <label className="details-card no-mrgin-left">
              {importFileList.totalLocationAccount?.toLocaleString() || "-"}
            </label>
          </div>
          <div className="p-4 bg-processing-file flex-align-center">
            <h5>Proccessing Files</h5>
            <label className="details-card no-mrgin-left">
              {importFileList.totalProcessingAccountFile?.toLocaleString() ||
                "-"}
            </label>
          </div>
        </div>
        {importFileList?.docs?.length > 0 ? (
          <div className="card shadow-sm rounded mt-4 m-3 table-overflow-style">
            <table className="table table-hover ">
              <thead className="thead-style">
                <tr>
                  <th scope="col" className="text-center"></th>
                  <th scope="col" className="text-left">
                    FileName
                  </th>
                  <th scope="col" className="text-left">
                    Data Source
                  </th>
                  <th scope="col" className="text-center">
                    Total Records
                  </th>
                  <th scope="col" className="text-center">
                    Processed
                  </th>
                  <th scope="col" className="text-center">
                    Imported
                  </th>
                  <th scope="col" className="text-center">
                    Import Percentage
                  </th>
                  <th scope="col" className="text-center">
                    Failed
                  </th>
                  <th scope="col" className="text-center">
                    Fail Percentage
                  </th>
                  <th scope="col" className="text-center">
                    Requested Date
                  </th>
                  <th scope="col" className="text-center">
                    Requested Day & Time
                  </th>
                  <th scope="col" className="text-center">
                    Status
                  </th>
                  <th scope="col" className="text-center">
                    Error Raised
                  </th>
                  <th scope="col" className="text-center">
                    Download
                  </th>
                </tr>
              </thead>
              <tbody>
                {importFileList?.docs?.map((data, i) => (
                  <tr key={data.id + data.jobId} className="table-body-style">
                    <td className="text-center IfFep">
                      {limit * (offset - 1) + i + 1}.
                    </td>
                    <td className="text-left IfFep">{data.fileName || "-"}</td>
                    <td className="text-left IfFep">
                      {data.fileSource || "-"}
                    </td>
                    <td className="text-center IfFep">
                      {data.rowCount.toLocaleString() || "-"}
                    </td>
                    <td className="text-center IfFep">
                      <label className="badge-style-count">
                        <span className="dot-style"></span>
                        <strong>
                          {data.processed.toLocaleString() || "-"}
                        </strong>
                      </label>
                    </td>
                    <td className="text-center IfFep">
                      <label className="badge-style-complete-count">
                        <span className="dot-style-complete"></span>
                        <strong>{data.imported.toLocaleString() || "-"}</strong>
                      </label>
                    </td>
                    <td className="text-center IfFep">
                      {data.rowCount
                        ? +(
                            ((((data.imported ?? 0) * 100) / data.rowCount) *
                              100) /
                            100
                          ).toFixed(2)
                        : 0}{" "}
                      %
                    </td>
                    <td className="text-center IfFep">
                      <label className="badge-style-failed-count">
                        <span className="dot-style-failed"></span>
                        <strong>{data.errored?.toLocaleString()}</strong>
                      </label>
                    </td>
                    <td className="text-center IfFep">
                      {data.rowCount
                        ? +(
                            ((((data.errored ?? 0) * 100) / data.rowCount) *
                              100) /
                            100
                          ).toFixed(2)
                        : 0}{" "}
                      %
                    </td>
                    <td className="text-center IfFep">
                      {dateFormat(data.createdAt, "mmmm dS, yyyy") || "-"}
                    </td>
                    <td className="text-center IfFep">
                      {dateFormat(data.createdAt, "dddd")}{" "}
                      {dateFormat(data.createdAt, "shortTime")}
                    </td>
                    <td className="text-center IfFep">
                      {data.status === "Failed" ? (
                        <label className="badge-style-failed">
                          <span className="dot-style-failed"></span>{data.status}
                        </label>
                      ) : data.status === "Completed" ? (
                        <label className="badge-style-complete">
                          <span className="dot-style-complete"></span>
                          {data.status}
                        </label>
                      ) : (
                        <label className="badge-style">
                          <span className="dot-style"></span>{data.status}
                        </label>
                      )}
                    </td>
                    <td className="text-center align-middle IfFep">
                      <Link
                        to={{
                          search: `type=account&fileName=${
                            encodeURIComponent(data.fileName)
                          }&startDateTime=${dateFormat(
                            data.createdAt,
                            "yyyy-mm-dd"
                          )}T${dateFormat(
                            data.createdAt,
                            "HH:MM"
                          )}&endDateTime=${dateFormat(
                            data.updatedAt,
                            "yyyy-mm-dd"
                          )}T${dateFormat(
                            data.updatedAt,
                            "HH:MM"
                          )}`,
                          pathname: `/dashboard/error`,
                        }}
                      >
                        <LaunchOutlined
                          sx={{
                            color: "#0062F5",
                            fontSize: 25,
                          }}
                        ></LaunchOutlined>
                      </Link>
                    </td>
                    <td className="text-center IfFep">
                      <Button
                        onClick={(e) => {
                          downloadData(e, data);
                        }}
                        style={{
                          backgroundColor: "transparent",
                          border: "none",
                        }}
                      >
                        <FileDownloadOutlined
                          sx={{
                            color: "#0062F5",
                            fontSize: 30,
                          }}
                        ></FileDownloadOutlined>
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div>
              <Paginate
                totalCount={importFileList.totalCount}
                limit={limit}
                offset={offset}
                setLimit={setLimit}
                setOffset={setOffset}
              />
            </div>
          </div>
        ) : (
          <DataNotFound></DataNotFound>
        )}
      </div>
    </>
  );
}

export default LiveAccount;
