import React from "react";
import { Routes, Route } from "react-router-dom";
import NotFound from "../components/NotFound/NotFound";
import Home from "../components/Home/Home";
import ErrorDashboard from "../components/ErrorDashboard/ErrorDashboard";
import DownloadDashboard from "../components/Downloads/DownloadDashboard";
import LiveDashboard from "../components/LiveDashboard/LiveDashboard";

export default function AppRoutes() {
  return (
    <Routes>
      {/* <Route exact path="/">
        {(window.location.href = "/auth/login.html")}
      </Route> */}
      <Route path="/" element={<Home />} />
      <Route path="/dashboard/error" element={<ErrorDashboard />} />
      <Route path="/dashboard/download" element={<DownloadDashboard />} />
      <Route path="/dashboard/live" element={<LiveDashboard />} />
      {/* <Route path="/login" element={<Login />} /> */}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}
