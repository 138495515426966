import { LocalizationProvider } from "@mui/x-date-pickers";
import "./App.css";
import SideNav from "./components/SideNav/SideNav";
import Navbar from "./layouts/Navbar/Navbar";
import AppRoutes from "./Routes/AppRoutes";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

function App() {
  
  console.log("Local name: " + localStorage.getItem("name"));
  if (!localStorage.getItem("name")) {
    window.location.href = window.location.origin + "/auth/login.html";
  }

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Navbar></Navbar>
        <div className="col-12 row">
          <div className="col-2 pt-5 100vh body-white">
            <SideNav></SideNav>
          </div>
          <div className="col-10 body-light-blue">
            <div className="container-fluid p-4">
              <AppRoutes></AppRoutes>
            </div>
          </div>
        </div>
      </LocalizationProvider>
    </>
  );
}

export default App;
