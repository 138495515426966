import axios from "axios";
import { errorInterceptor, updateHeaderInterceptor } from "./api-interceptor";

const httpClient = axios.create({

baseURL: process.env.REACT_APP_BASE_URL,
});

errorInterceptor(httpClient);

updateHeaderInterceptor(httpClient);

export default httpClient;